<template>
  <div>
    <PageContainer
      v-if="isEventPageAvailable"
      :params="templateParams"
      :lang="lang"
      class="min-h-[100dvh] flex flex-col"
      :class="`page--${pageType}`"
    >
      <PageDecline
        v-if="pageType === 'decline'"
        class="flex-1"
        :event="event"
        :guest="guest"
        :lang="lang"
        :template="event.pages?.activeTemplate"
        @refresh-guest="refreshGuest"
      />
      <PageCalendar
        v-else-if="pageType === 'calendar'"
        class="flex-1"
        :event="event"
        :guest="guest"
        :lang="lang"
        :template="event.pages?.activeTemplate"
      />
      <PageQr
        v-else-if="pageType === 'qr'"
        class="flex-1"
        :event="event"
        :guest="guest"
        :lang="lang"
        :template="event.pages?.activeTemplate"
      />
      <template v-else>
        <div
          v-for="section in filterSectionsByVisibility(event.pages?.activeTemplate?.sections)"
          :key="section.id"
        >
          <component
            :is="resolveComponent(`page-${_kebabCase(section.type)}`)"
            :id="`section-${section.id}`"
            :key="section.id"
            :data="section.data"
            :guest="guest"
            :event="event"
            :stats="stats"
            :page-id="route.query?.p"
            :workspace="useWorkspaceStore().$state"
            @refresh-stats="refreshStats"
          />
        </div>
      </template>
    </PageContainer>
    <div
      v-else
      v-loading="isEventLoading"
      class="py-20 px-5 text-center"
    >
      <div v-if="!isEventLoading">
        <div class="min-h-[23.875rem] mb-4">
          <Lottie
            :animation-data="error404Lottie"
            :height="382"
            :width="495"
            :loop="false"
            class="max-w-full"
          />
        </div>
        <h2 class="text-page-5xl font-bold mb-2">
          This page no longer exists
        </h2>
        <div class="text-gray-500">
          It may have been deleted or the link you followed may be incorrect.
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import error404Lottie from '~/assets/json/lottie/error-404.json';

definePageMeta({
  layout: 'page',
  public: true,
});

const route = useRoute();
const { data: event, pending: isEventLoading } = await useAsyncData('event', () => useEventStore().getEventPublic({ _id: route.params?.event }));
const { data: stats, refresh: refreshStats } = await useAsyncData('stats', () => useApi.get(`/public/guests/stats?event=${route.params?.event}`).catch(() => null));
const { data: guest, refresh: refreshGuest } = await useAsyncData('guest', () => {
  if (route.query?.g) {
    return useApi.get(`/public/guests/${route.query?.g}?event=${route.params?.event}`).catch(() => null);
  }

  return null;
});
const i18n = useI18n();

// Computed
const pageType = computed(() => {
  if (route.query?.d) {
    return 'decline';
  }

  if (route.query?.cal) {
    return 'calendar';
  }

  if (route.query?.qr) {
    return 'qr';
  }

  if (route.query?.g) {
    return 'confirmation';
  }

  if (route.query?.p && route.query?.p === event?.value?.pages?.activeTemplate?._id) {
    return 'registration';
  }

  return false;
});
const pageTitle = computed(() => {
  const eventTitle = templateParams.value?.general?.[`_${lang.value}`]?.eventTitle || '';

  if (pageType.value === 'decline') {
    return i18n.t('xyz17', { value: eventTitle });
  }

  if (pageType.value === 'calendar') {
    return i18n.t('xyz1', { value: eventTitle });
  }

  if (pageType.value === 'qr') {
    return i18n.t('xyz14', { value: eventTitle });
  }

  return eventTitle;
});

const isEventPageAvailable = computed(() => {
  if (!event.value) {
    return false;
  }

  if (pageType.value === 'decline' || pageType.value === 'calendar' || pageType.value === 'qr') {
    return !!guest?.value;
  }

  if (pageType.value === 'confirmation') {
    return !!guest?.value && !!event?.value?.pages?.activeTemplate;
  }

  if (pageType.value === 'registration') {
    return !!event?.value?.pages?.activeTemplate;
  }

  return false;
});

const templateParams = computed(() => {
  const params = event?.value?.pages?.activeTemplate?.params || {};

  if (pageType.value === 'decline' || pageType.value === 'calendar' || pageType.value === 'qr') {
    params.appearance = 'light';
  }

  return params;
});
const lang = computed(() => {
  if ((event.value?.general?.langs || []).includes(route.query?.lang)) {
    return route.query?.lang;
  }

  if ((event.value?.general?.langs || []).includes(guest?.value?.properties?.lang)) {
    return guest?.value?.properties?.lang;
  }

  return event.value?.general?.langs?.[0];
});

// Methods
const filterSectionsByVisibility = (sections) => (sections || []).filter((section) => {
  if (section.data?.hasRestrictedVisibility) {
    return (section.data?.visibleOnlyTo || []).some((property) => {
      const [propertyId, propertyValue] = property.split('/');
      const propertyInEvent = event.value?.guestlist?.properties?.find((property) => property.id === propertyId && (property.options.some((option) => option.id === propertyValue) || !propertyValue));

      if (!propertyInEvent) {
        return true;
      }

      const guestPropertyValue = guest?.value?.properties?.[propertyId];

      if (!propertyValue) { // if propertyValue is empty, allow only for guests that don't have any value in property
        return !guestPropertyValue?.length;
      }

      return guestPropertyValue?.includes(propertyValue);
    });
  }

  return true;
});

useHead({
  base: [
    { target: '_blank' },
  ],
  link: event?.value?.planTier !== 'basic'
    ? [
      { rel: 'icon', key: 'icon', type: 'image/png', href: `${useRuntimeConfig().public.imagekit}${useRuntimeConfig().public.imagekit ? '/tr:w-512,h-512,c-at_max,cm-pad_resize/' : ''}${useWorkspaceStore().identity?.logo}` },
      { rel: 'apple-touch-icon', key: 'apple-touch-icon', href: `${useRuntimeConfig().public.imagekit}${useRuntimeConfig().public.imagekit ? '/tr:w-512,h-512,c-at_max,cm-pad_resize/' : ''}${useWorkspaceStore().identity?.logo}` },
    ]
    : [],

  meta: pageType.value === 'registration'
    ? []
    : [
      {
        name: 'robots',
        content: 'noindex',
      },
    ],
});

useSeoMeta({
  title: pageTitle,
  ogTitle: pageTitle.value && pageTitle, // title.value here is hackfix
});

// Watchers
watch(lang, (locale) => {
  if (locale) {
    i18n.setLocale(locale);
    i18n.locale.value = locale;
  }
}, { immediate: true });

// redirect to active template if query param is different
if (route.query?.p && route.query?.p !== event?.value?.pages?.activeTemplate?._id) {
  const response = await useTemplatesStore().getActiveTemplate({
    event: event.value?._id,
    template: route.query?.p,
  });

  if (response?._id) {
    navigateTo({ path: route.path, query: { ...route.query, p: response._id } });
  }
}
</script>

<style>
.page--decline {
  @apply flex flex-col;
}

.page--calendar {
  @apply flex flex-col;
}

.page--qr {
  @apply flex flex-col;
}
</style>
